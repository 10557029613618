.Container {
  display: inline-block;
  fill: var(--color-gray1);
}

.Query {
  width: 100%;
  vertical-align: top;
  height: 50px;
}

.Items {
  font-family: "Open Sans";
  position: relative;
  z-index: 1;
  width: 100%;
  max-height: 280px;
  overflow-y: scroll;
  background-color: #ffffff;
  border: var(--color-gray3) 1px solid;
  border-top: 0;
  border-radius: 16px;
  transition: max-height 200ms ease;
  margin-top: 10px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
}

.Container:not(.Expanded) > .Items {
  max-height: 0;
  border-bottom: 0;
}

.Item {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: 0;
  padding: 8px 16px;
  cursor: pointer;
}

.Item:hover {
  background-color: var(--color-gray4);
}
