.Container {
  height: 72px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--color-grey700);
}

.Content {
  height: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .Container {
    display: none;
  }
}

.LogoLoggedIn {
  font-weight: 600;
  font-size: 25px;
  color: #ffffff;
  letter-spacing: -0.035em;
  display: flex;
  align-items: center;
  position: relative;
}

.LogoLoggedIn a {
  margin-right: 32px;
  text-decoration: none;
  color: white;
}

.LogoLoggedOut {
  font-weight: 800;
  font-size: 25px;
  color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
}

.LogoLoggedOut a {
  margin-right: 32px;
  text-decoration: none;
  color: white;
}

.OwnProfile div::after {
  content: "";
  padding: 2.5px 72px;
  background-color: var(--color-primary500);
  position: absolute;
  border-radius: 4px;
  bottom: -4px;
  left: 87px;
}

.About {
  position: relative;
}

.About span {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.About.AboutHighlight span::after {
  content: "";
  padding: 2.5px 24px;
  background-color: var(--color-primary500);
  position: absolute;
  border-radius: 4px;
  bottom: -4px;
  left: -2px;
}

.About.HelpHighlight span::after {
  content: "";
  padding: 2.5px 20px;
  background-color: var(--color-primary500);
  position: absolute;
  border-radius: 4px;
  bottom: -4px;
  left: -2px;
}

.Globe {
  height: 48px;
  width: 48px;
  vertical-align: middle;
}

.Search {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.SearchInput {
  max-width: 600px;
  width: 100%;
}

.SearchInput input::placeholder {
  color: white;
}

.SearchInputWrapper {
  background-color: var(--color-grey600);
  transition: background-color 0.2s ease;
  width: 25vw;
  max-width: none;
}

.SearchInputWrapper input {
  color: white;
}

.SearchInputWrapper > div > svg > path {
  stroke: white;
}

.SearchInputWrapper:focus-within {
  background-color: white;
}

.SearchInputWrapper:focus-within input {
  color: var(--color-grey900);
}

.SearchInputWrapper:focus-within > div > svg > path {
  stroke: var(--color-grey500);
}

.Menu {
  grid-column: 3;
  display: flex;
  align-items: center;
  fill: #ffffff;
  justify-self: flex-end;
}

.Menu > *:not(:last-child) {
  margin-right: 18px;
}

.Menu > a,
.Menu > button {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.Account {
  vertical-align: top;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  outline: 0;
}

.ContextOptions {
  min-width: 180px;
}

.ContextOptions > *:not(:first-child) {
  margin-top: 16px;
}

.ContextOptions h4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.ContextOptions .CreateButton {
  color: var(--color-grey700);
  fill: var(--color-grey600);
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}

.ContextOptions svg {
  height: 15px;
  width: 15px;
}

.ContextOptions .AccountButton {
  color: #ffffff;
}

.EditAccountButton {
  cursor: pointer;
  color: #ffffff;
}

.ContextOptions .SignOut {
  color: var(--color-red1);
}

.ContextDrafts {
  height: 18px;
}

.DraftIcon {
  display: inline-block;
  font-size: 14px;
  margin-right: 8px;
  background-color: var(--color-orange0);
  border-radius: 50%;
  line-height: 20px;
  height: 20px;
  width: 20px;
  color: #ffffff;
}

.DraftTitle {
  display: block;
  margin-bottom: 16px;
}

.DraftList {
  max-height: 300px;
  overflow: auto;
}

.DraftList li {
  display: flex;
  justify-content: space-between;
}

.DraftList li:not(:first-child) {
  margin-top: 12px;
}

.VenueName {
  color: var(--color-gray5);
}

.TipDraftButton {
  line-height: 20px;
  font-size: 14px;
}

.DraftsButton {
  align-items: center;
  display: flex;
  font-weight: 600;
}

.CreateNewButton {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 600;
}
