.Container {
  display: grid;
  grid-template-rows: max-content 1fr;
  background-color: #ffffff;
  max-width: 600px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  max-height: 440px;
}

.Container.TipListView {
  max-width: 600px;
}

.Header {
  position: relative;
  text-align: center;
  padding: 30px;
}

.BackButton {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
  fill: var(--color-gray1);
}

.DialogTitle {
  font-family: "Marcellus";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: black;
  text-align: left;
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  fill: var(--color-gray1);
}

.Content {
  overflow: auto;
  margin: 0 10px 10px;
  padding: 0 14px;
}

.Items {
  display: flex;
  flex-flow: column;
}

.Items > *:not(:first-child) {
  margin-top: 20px;
}

.TipList,
.User {
  overflow: hidden;
}

.User {
  display: grid;
  grid-template-columns: 75px 1fr;
}

.Image {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
}

.Info {
  font-size: 13px;
  display: flex;
  flex-flow: column;
  line-height: 1.5em;
  align-items: flex-start;
  border-bottom: 1px solid var(--color-grey200);
  margin-top: 5px;
}

.Name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray7);
}

.FollowedCount {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: var(--color-primary600);
  fill: var(--color-primary600);
  margin-left: -8px;
}

.FollowedCount > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: var(--color-primary600);
}

.Description {
  color: var(--color-grey400);
  font-family: "Open Sans";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

.Close {
  width: 100%;
  height: 38px;
}

@media screen and (max-height: 820px) {
  .Container {
    height: 100%;
  }
}

@media screen and (max-width: 900px) {
  .Container {
    position: fixed;
    background-color: white;
    left: 0;
    max-width: 100%;
    max-height: 504px;
    padding: 24px;
    top: 100vh;
    transition: transform 0.2s ease;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .Container.TipListView {
    max-width: 100%;
  }

  .Content {
    margin-bottom: 8px;
    max-width: 80vw;
  }

  .Header {
    display: flex;
    flex-direction: row;
  }

  .BackButton {
    position: relative;
    top: -10px;
    left: -20px;
  }
}
