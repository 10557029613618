.Container.Small {
  display: flex;
}

.ContainerDialog {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.InfoBox {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-grey200);
  width: 400px;
}

.NameBox {
  display: flex;
  flex-direction: row;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-grey400);
  fill: var(--color-grey400);
  gap: 10px;
  cursor: default;
}

.FollowerBox {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.FollowerIcon {
  margin-top: 2px;
}

.NameText {
  color: var(--color-grey400);
  margin-bottom: 5px;
}

.Header {
  top: 8px;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.Image {
  border-radius: inherit;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ImageAvatar {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.Options {
  position: absolute;
  top: 8px;
  right: 8px;
}

.Button * {
  color: var(--color-grey700);
  fill: var(--color-grey600);
  font-size: 14px;
}

.Button > * {
  display: block;
  width: 100%;
  text-align: left;
  padding: 6px;
}

.Button svg {
  vertical-align: middle;
  margin-right: 8px;
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
}

.RemoveButton * {
  color: var(--color-red0);
  fill: var(--color-red0);
  font-size: 14px;
}

.Content {
  flex-basis: calc(100% - 60px);
  padding: 6px;
  position: relative;
}

.Title {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  word-break: break-word;
}

.TitleTip {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: black;
}

.Location {
  font-size: 16px;
  font-weight: 100;
  margin-bottom: 4px;
  word-break: break-word;
}

.Description {
  margin-bottom: 8px;
  word-break: break-word;
}

.ModalTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}
