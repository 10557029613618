.Container {
  background-color: var(--color-grey100);
  max-width: 680px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  height: fit-content;
}

.Header {
  position: relative;
  padding: 24px 30px;
}

.Header > h5 {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  fill: var(--color-gray1);
}

.ImageFile {
  display: none;
}

.AddPhotoButton {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  fill: #ffffff;
  stroke: var(--color-gray1);
}

.ProfileImage {
  height: 104px;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 15px;
  border: 2px dashed var(--color-grey400);
  justify-content: center;
  align-items: center;
  display: flex;
}

.ProfileImage > span {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ProfileImage > span > div {
  margin-top: 8px;
}

.ProfileImage > img {
  object-fit: contain;
  height: 100%;
}

.ImageDropOverlay {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 70, 77, 0.8);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-shadow: 0 0 5px #000000;
  line-height: 1.2em;
}

.ImageDropOverlay::before {
  display: block;
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: var(--color-blue0) 3px dashed;
  border-radius: inherit;
}

.Info {
  display: grid;
  gap: 24px;
  padding: 24px;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
}

.Input {
  width: 100%;
  position: relative;
}

.Copy {
  font-family: "Open Sans";
  cursor: pointer;
  color: var(--color-grey800);
  text-align: right;
  margin-right: 4px;
}

.Copy * {
  fill: var(--color-grey800);
  margin-left: 4px;
}

.Slug {
  position: relative;
  top: 5px;
}

.SlugCheck {
  fill: var(--color-success300);
}

.FullSpan {
  grid-column: 1 / -1;
}

.CharCounter {
  font-family: "Open Sans";
  color: var(--color-gray1);
  font-size: 12px;
  padding-left: 16px;
}

.Actions {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px;
  margin-left: auto;
}

.Change {
  font-family: "Open Sans";
  justify-content: flex-start;
  color: var(--color-blue0);
  text-decoration: underline;
  padding-left: 29px;
  padding-top: 7px;
  cursor: pointer;
}

.Hstack {
  display: flex;
  flex-direction: row;
}

.Vstack {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.Actions > *:not(:first-child) {
  margin-left: 16px;
}

@media only screen and (max-width: 900px) {
  .Info {
    gap: 4px;
    grid-template-columns: 50% 50%;
  }
  .Container {
    max-width: 100%;
    border-radius: 0px;
    height: 100%;
    box-shadow: none;
  }
  .Actions {
    margin-left: 0;
    width: 100%;
  }
  .ActionButton {
    width: 100%;
  }
}
