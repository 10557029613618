.Container {
  background-color: rgba(242, 244, 247, 1);
  width: 680px;
  height: min(90%, 600px);
  overflow: auto;
  padding: 8px 24px;
  border-radius: 16px;
}

@media only screen and (max-width: 900px) {
  .Container {
    height: 100%;
    width: 100%;
  }
}

.Content {
  max-width: 800px;
}

.Title {
  font-family: "Marcellus";
  display: flex;
  justify-content: space-between;
  margin: 24px 0 8px 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.Input {
  width: 100%;
}

.CharCounter {
  font-family: "Open Sans";
  color: var(--color-gray1);
  font-size: 12px;
  padding-left: 16px;
}

.CloseButton {
  fill: var(--color-gray1);
}

.ActionsCreate {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.ActionsCreate > div > button {
  height: 48px;
  width: 130px;
}
.ActionsCreate > div:first-child > button {
  width: 90px;
}

.ActionsEdit {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.ActionsEdit > div > button {
  height: 48px;
  width: 100%;
  margin-bottom: 10px;
}
.ActionsEdit > div:nth-child(3) {
  order: -1;
}
