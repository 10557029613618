.Status {
  display: block;
  position: absolute;
  top: 24px;
  left: 32px;
  padding: 4px 12px;
  border-radius: 15px;
  color: #ffffff;
  user-select: none;
  font-size: 14px;
  line-height: 20px;
}

.Status.Draft {
  background-color: var(--color-orange0);
  z-index: 1;
}

.Status.Published {
  background-color: var(--color-green0);
}

.MoreInfo {
  font-size: 18px;
  margin-top: 8px;
}

.MoreInfo > .Icon {
  vertical-align: middle;
  margin-right: 8px;
  position: relative;
  top: -1px;
  height: 24px;
  width: 24px;
  fill: var(--color-grey400);
}

.MoreInfo .DetailsIcon {
  fill: none;
  stroke: var(--color-grey400);
}

.MoreInfo span {
  color: var(--color-grey500);
  font-size: 14px;
}

.VenueDetails {
  margin-top: 8px;
  word-break: break-word;
  max-width: 300px;
}

.VenueDetails > li {
  display: grid;
  padding: 8px 0;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;
}

.VenueDetails > li > .Icon {
  vertical-align: top;
  width: 25px;
  margin-right: 8px;
}

.Photo {
  width: 230px;
  height: 230px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.Attribution {
  position: absolute;
  display: flex;
  bottom: 0px;
  color: white;
  left: 0;
  justify-content: center;
  width: 100%;
  background: linear-gradient(
    to bottom,
    transparent 10%,
    var(--color-grey700) 90%
  );
  padding-bottom: 8px;
  padding-top: 12px;
}

.Attribution a {
  margin-left: 10px;
  color: white;
}

.Title {
  font-weight: 700;
  font-size: 16px;
  color: var(--color-grey900);
  line-height: 30px;
}

.Location {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.Content {
  position: relative;
  display: grid;
  grid-template-columns: 230px auto;
  grid-template-rows: 230px 80px;
  width: 100%;
  padding: 24px;
  column-gap: 24px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  height: auto;
}

.Rubric {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.Rubric path {
  fill: white;
}

.Rubric > div {
  margin-left: 16px;
}

.Description {
  font-family: "Open Sans";
  margin-bottom: 8px;
  word-break: break-word;
  color: var(--color-grey500);
  line-height: 22px;
}

.TipInformation {
  width: auto;
  display: grid;
  grid-template-rows: 52px minmax(160px, max-content);
  padding-bottom: 8px;
}

.DetailButtons {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 24px;
  left: 0px;
  width: 100%;
  border-top: 1px solid var(--color-grey200);
  padding-top: 16px;
}

.DetailButtons > *:first-child {
  padding-left: 24px;
}

.DetailButtons > * {
  margin-right: 20px;
}

.Borrow {
  display: flex;
  position: absolute;
  bottom: 16px;
  right: 16px;
  gap: 12px;
}

.User div {
  font-family: "Open Sans";
  margin-top: 10px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-primary600);
}

.Phone {
  display: none;
}

.User.Phone {
  margin-top: 8px;
  margin-left: 16px;
  font-weight: 600;
  margin-bottom: -4px;
}

.Bold {
  font-weight: bold;
}

@media only screen and (min-width: 1600px) {
  .TipInformation {
    grid-template-rows: 70px 110px minmax(30px, max-content);
    position: relative;
  }
  .Content {
    grid-template-rows: auto;
  }
  .DetailButtons {
    position: static;
    border: none;
    padding-top: none;
  }
  .Borrow {
    bottom: 0;
    right: 0;
  }
  .Title {
    font-size: 20px;
  }
  .Description {
    line-height: 24px;
  }
}

@media only screen and (max-width: 900px) {
  .Content {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 0;
  }
  .DetailButtons > *:first-child {
    padding-left: 0px;
  }
  .Desktop {
    display: none;
  }
  .Phone {
    display: inherit;
  }
  .Rubric {
    padding: 16px 32px 16px 16px;
    margin: 0;
  }
  .Rubric h2 {
    line-height: 24px;
  }
  .TipInformation {
    display: flex;
    flex-direction: column;
  }
  .DetailButtons {
    bottom: 0;
    padding: 16px 0;
    position: relative;
    width: calc(100% - 32px);
    align-self: center;
  }
  .Description {
    padding: 16px;
    line-height: 24px;
  }
  .Photo {
    height: 175px;
    width: 100%;
    border-radius: 0;
  }

  .DetailButtons .MoreInfo:first-child span {
    display: none;
  }
  .Attribution {
    justify-content: start;
    padding-left: 16px;
  }
  .Status {
    top: 84px;
    left: 16px;
  }
}
