.Container {
  border: 0;
  background-color: transparent;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: inherit;
  outline: 0;
  cursor: pointer;
  padding: 0;
  line-height: inherit;
}

.Text.Primary {
  color: var(--color-blue0);
}

.Text.Secondary {
  color: var(--color-grey700);
}

.Text.Link {
  color: #ac4d8b;
}

.Text.Purple {
  color: var(--color-primary600);
  font-weight: 600;
}

.Text.Delete {
  color: #b42318;
}

.Fill,
.Outline {
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  font-weight: 600;
}

.Big {
  font-size: 18px;
  width: 150px;
  height: 60px;
}

.Outline.Primary {
  color: var(--color-blue0);
  border: var(--color-blue0) 1px solid;
}

.Outline.Purple {
  color: var(--color-primary600);
  border: var(--color-primary600) 1px solid;
}

.Outline.Secondary {
  color: var(--color-grey500);
  border: var(--color-grey500) 1px solid;
}

.Outline.White {
  color: white;
  border: #d0d5dd 1px solid;
}

.Outline.Delete {
  color: #b42318;
  border: #b42318 1px solid;
}

.Fill.Primary {
  background-color: var(--color-blue0);
  color: #ffffff;
}

.Fill.Purple {
  background-color: var(--color-primary600);
  color: #ffffff;
}

.Fill.Secondary {
  background-color: #000000;
  color: #ffffff;
}

.Fill.Third {
  background-color: #ffffff;
  color: #000000;
}

.Fill.Fourth {
  background-color: var(--color-red0);
  color: #ffffff;
}

.Fill.Fifth {
  background-color: var(--color-orange0);
  color: #ffffff;
}

.Fill.White {
  color: #344054;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #ffffff;
}
.Text.Pink {
  color: var(--color-pink700);
  font-weight: 600;
}
