.ReportReason {
  flex-direction: column;
  display: flex;
  background-color: white;
  border-radius: 10px;
}

.InputReport {
  margin-bottom: 20px;
  border-radius: 10px;
}

.ModalTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
}

.CloseButton {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
  fill: var(--color-gray1);
}

.Body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 50px 20px 50px;
}
