.Container {
  font-family: "Open Sans";
  display: inline-block;
  border-radius: 8px;
  background-color: white;
  border: var(--color-grey300) 1px solid;
  font-size: 16px;
  letter-spacing: -0.04em;
  height: fit-content;
  vertical-align: top;
  overflow: auto;
}

.Container.HasFocus {
  border-color: var(--color-blue0);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E6F0FA;
  box-sizing: border-box;
}

.Label {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  margin-bottom: 8px;
  font-size: 16px;
  padding: 16px 0 0;
  color: var(--color-grey700);
  pointer-events: none;
  font-weight: 600;
}

.TextArea {
  height: 49px;
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  border-radius: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 16px 16px 0;
  line-height: 1.3em;
  resize: none;
}


