.ImageFile {
  display: none;
}

.Image {
  background-color: white;
  border-radius: 15px;
  text-align: center;
  position: relative;
  margin-top: 48px;
}

.DropArea {
  padding: 50px 25px;
  border-radius: 15px;
  border: 2px dashed var(--color-grey400);
  color: var(--color-grey500);
}

.HasImage.DropArea {
  background-color: var(--color-grey200);
}

.Overlay {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 133, 255, 0.4);
}

.Label {
  position: absolute;
  display: block;
  font-size: 16px;
  color: var(--color-grey700);
  pointer-events: none;
  font-weight: 600;
  text-align: start;
  height: 0;
  top: -32px;
}

.Error {
  width: 100%;
  text-align: flex-start;
  margin-top: 8px;
  margin-bottom: 24px;
}

.Preview {
  display: flex;
  flex-flow: column;
  align-items: center;

  border: 2px dashed var(--color-grey400);
  border-radius: 15px;
}

.PreviewItems {
  width: 95%;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 16px;
}

.PreviewItems > img {
  max-width: 40%;
  max-height: 200px;
  margin-bottom: 20px;
}

.PreviewItems > .RemoveButton {
  color: var(--color-red0);
}

.PreviewImage {
  width: 50%;
  display: flex;
  align-items: center;
  margin-right: 8px;
  justify-content: center;
  max-height: 175px;
}

.PreviewImage > img {
  max-width: 100%;
  max-height: 175px;
  border-radius: 15px;
}

.PreviewDropArea {
  width: 50%;
  margin-left: 8px;
}

.Preview > .RemoveButton {
  color: var(--color-red0);
  margin-bottom: 10px;
}
