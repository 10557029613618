.Container {
  height: 100px;
  width: calc(100% - 4px);
  display: flex;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  margin: 2px;
}

.Image {
  object-fit: cover;
  border-radius: 8px 0 0 8px;
  background-color: var(--color-grey400);
}

.Title {
  color: var(--color-grey900);
  font-size: 16px;
  font-weight: 800;
  word-break: break-word;
  line-height: 24px;
  cursor: pointer;
}

.Info {
  font-size: 14px;
  color: var(--color-grey400);
  font-weight: 400;
  margin-left: 8px;
  width: 200px;
  margin-top: 15px;
}

.Info > * {
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Info h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
}

.Venue {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: black;
}

.TipCreator {
  font-size: 14px;
  font-weight: 600px;
  line-height: 20px;
  color: var(--color-primary600);
  cursor: pointer;
  margin-right: 8px;
}

.BorrowCount span {
  margin-left: 2px;
}

.BorrowCount svg {
  fill: var(--color-grey400);
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}
