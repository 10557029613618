.Container {
  display: inline-flex;
  width: 32px;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  flex-flow: column;
}

.Container > span {
  display: block;
  width: 5px;
  height: 5px;
  background-color: var(--color-grey400);
  border-radius: 50%;
}
