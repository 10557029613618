.Container {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  padding: 10px 10px 20vh;
  z-index: 3000;
}

.Notification {
  position: relative;
  max-width: 450px;
  width: 100%;
  border-radius: 8px;
}

.Wrapper {
  display: flex;
  justify-content: center;
  padding-right: 2px;
  pointer-events: all;
  border-radius: 8px;
  font-weight: 600;
  padding: 10px;
  flex-direction: column;
}

.Neutral {
  background: var(--color-success25);
  border: var(--color-success300) 1px solid;
  color: var(--color-success700);
}

.Neutral a {
  color: var(--color-success700);
}

.Neutral > div > Button {
  fill: var(--color-success700);
}

.Alert {
  background: var(--color-warning25);
  border: var(--color-warning300) 1px solid;
  color: var(--color-warning700);
}

.Alert a {
  color: var(--color-warning700);
}

.Alert > div > Button {
  fill: var(--color-warning700);
}

.Error {
  background: var(--color-error25);
  border: var(--color-error300) 1px solid;
  color: var(--color-error700);
}

.Error a {
  color: var(--color-error700);
}

.Error > div > Button {
  fill: var(--color-error700);
}

.Icon {
  margin-right: 10px;
}

.Close {
  margin-left: auto;
}

.TopBox {
  margin-top: 6px;
  width: 100%;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.Message {
  margin-left: 30px;
}
