.Container {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  background-color: white;
  border: var(--color-grey300) 1px solid;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: -0.04em;
  height: 50px;
  vertical-align: top;
  outline: 0;
  user-select: none;
}

.Container.HasFocus {
  border-color: var(--color-blue0);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #e6f0fa;
  box-sizing: border-box;
}

.Select {
  display: none;
}

.Icon {
  display: flex;
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.Chevron {
  display: flex;
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.Label {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  margin-bottom: 8px;
  font-size: 16px;
  padding: 16px 0 0;
  color: var(--color-grey700);
  pointer-events: none;
  font-weight: 600;
}

.Icon + .Label {
  padding-left: 40px;
}

.Container.HasValue > .Label {
  font-size: 14px;
  padding-top: 6px;
}

.Header {
  display: block;
  height: 100%;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  border-radius: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 2px 42px 0;
  line-height: 3em;
  cursor: pointer;
  position: relative;
}

.Header h4 {
  margin-top: 3px;
  font-weight: bold;
}
.Header h5 {
  margin-top: 0;
  font-weight: bold;
}

.Label + .Header {
  margin-top: -16px;
  transition: margin-top 100ms;
  line-height: 1.95em;
}

.Icon + .Label + .Header {
  padding-left: 40px;
}

.Container.HasValue > .Label + .Header {
  padding-top: 18px;
  margin-top: -6px;
}

.Placeholder {
  color: var(--color-gray1);
}

.Options {
  margin-top: 10px;
  padding: 30px 16px;
  position: relative;
  top: 1px;
  z-index: 100;
  display: flex;
  flex-flow: column;
  background-color: #ffffff;
  border-top: 0;
  border-radius: 16px;
  overflow: auto;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
}

.Option > span {
  display: block;
  outline: 0;
  padding: 8px 16px;
  cursor: pointer;
}

.Option.Hovered {
  background-color: var(--color-gray4);
}

.DisableFirst > li:first-child {
  background-color: white;
}

.Borders > li:not(:first-child) {
  border-top: solid 1px var(--color-grey200);
}
