.Container {
  fill: #000000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.Container svg {
  fill: inherit;
  display: block;
}

.Container.Circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
