.ExtraText {
  color: white;
  background: var(--color-grey600);
  border-radius: 8px;
  position: absolute;
  width: auto;
  padding: 7px 15px;
  z-index: 5;
  text-align: center;
  transform: translateY(-50%);
}

.SquaredBox {
  fill: var(--color-grey600);
  position: absolute;
  z-index: 5;
}

.BoxPosition {
  left: 88px;
  bottom: -6px;
}
