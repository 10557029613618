.Container {
  background-color: white;
  width: 400px;
  min-height: 408px;
  border-radius: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.Header {
  width: 100%;
  height: 160px;
  border-radius: 8px;
  overflow: hidden;
}

.Header > img {
  height: 100%;
  width: 100%;
  /* background-color: darksalmon; */
}

.Title {
  margin-top: 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-grey900);
}

.Text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-grey500);
  margin-top: 8px;
}

.Meatballs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.Meatballs > span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 6px;
  transition: background-color 0.2s ease;
}

.Buttons {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.Buttons > button {
  width: 170px;
  height: 44px;
}
