.Container {
  font-family: "Open Sans";
  line-height: 1em;
  font-size: 16px;
  display: inline-block;
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.HeaderBar {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 8px;
}

.BackButton {
  padding-top: 8px;
  padding-left: 14px;
  padding-right: 14px;
}

.Wrapper {
  width: 80vw;
  height: 44px;
  max-width: 380px;
  background-color: #ffffff;
  border-radius: 8px;
  width: 80vw;
}

.InputWrapper {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  width: 100%;
}

.WrapperExpandedMobile {
  height: 44px;
  background-color: #ffffff;
  border-radius: 0px;
  width: 100vw;
  padding-top: 40px;
}

.InputWrapperExpandedMobile {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  border: 1px black solid;
  border-radius: 8px;
  width: 100%;
  margin-right: 16px;
}

.Icon {
  margin-top: 2px;
  margin-right: 10px;
}

.Icon path {
  stroke: var(--color-grey500);
}

.Input {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  border-radius: inherit;
  outline: 0;
  border: 0;
  background-color: inherit;
  padding: 0;
  width: 100%;
}

.Input::placeholder {
  color: var(--color-gray1);
  font-weight: 100;
}

.SearchResultWrapper {
  position: relative;
  top: 5px;
  left: -65px;
  max-width: 510px;
  border-radius: 16px;
  background-color: white;
  width: 80vw;
}

.SearchResult {
  color: #000000;
  margin: 0 8px;
  max-height: calc(70vh - 120px);
  overflow-y: auto;
  padding: 16px 8px;
}

.List {
  margin-top: 8px;
}

.List span.Item:not(:first-child)::after {
  content: "";
  background: var(--color-grey200);
  position: absolute;
  top: 0px;
  right: 24px;
  height: 1px;
  width: 376px;
}

.List button {
  margin-top: 4px;
  margin-left: 16px;
}

.Title {
  display: block;
}

.Item {
  outline: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 12px;
  border-radius: 8px;
  color: unset;
  position: relative;
  height: 100%;
}

.Item:hover {
  background-color: var(--color-gray4);
}

.Item > * {
  display: inline-block;
}

.Item > *:not(:first-child) {
  margin-left: 8px;
}

.Highlight {
  color: var(--color-blue0);
}

.Details {
  margin: 8px;
  color: var(--color-gray500);
}

.ProfileImage {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.List .divider:last-child {
  display: none;
}

.divider {
  border-top: 1px solid var(--color-grey200);
  width: 60%;
  margin: 0 0 10px 0;
  position: relative;
  left: 90px;
  background-color: none;
  bottom: -5px;
}

.Backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1;
}

@media only screen and (max-width: 900px) {
  .Backdrop {
    background-color: white;
  }

  .MobileWrapper {
    display: flex;
    justify-content: center;
  }

  .SearchResultWrapper {
    left: auto;
    top: 0px;
    height: 90vh;
    border-radius: 0px;
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  .SearchResult {
    width: 100vw;
    max-height: fit-content;
    margin-right: 0px;
    padding-right: 0px;
  }
  .Item {
    padding-right: 0px;
  }

  .List span.Item:not(:first-child)::after {
    width: auto;
    left: 0px;
    margin-left: 80px;
    margin-right: 10px;
  }
}
