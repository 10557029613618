.Container {
  background-color: rgba(242, 244, 247, 1);
  width: 1200px;
  height: min(90%, 800px);
  overflow: hidden;
  border-radius: 16px;
  position: relative;
}

.Content {
  display: grid;
  grid-template-columns: 388px auto;
  height: 100%;
  position: relative;
}

.Title {
  display: flex;
  margin: 24px 0 8px 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.Title > span {
  margin-left: 14px;
  width: 290px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.CloseButton {
  fill: var(--color-grey900);
  position: relative;
  top: 3px;
}

.Map {
  background-color: brown;
  height: 100%;
}

.TipsColumn {
  padding: 0 24px;
  position: relative;
  height: 800px;
}

.Tips > *:not(:first-child) {
  margin-top: 16px;
}

.Tips {
  overflow: hidden;
  height: 670px;
  padding-bottom: 32px;
}

.Tips:hover {
  overflow-y: auto;
}

.Selected > * {
  outline: 2px solid var(--color-blue500);
}

.User {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
}

.Avatar {
  height: 40px !important;
  width: 40px !important;
}

.Avatar path {
  stroke: none;
}

.User > div {
  margin-left: 16px;
}

.User svg {
  fill: var(--color-grey400);
  height: 16px;
  width: 16px;
}

.User img {
  border-radius: 50%;
}

.TipListCreator {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--color-primary600);
}

.UserStats {
  color: var(--color-grey400);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  margin-top: 2px;
  margin-bottom: -5px;
}

.UserStats > span {
  margin-left: 6px;
  margin-right: 12px;
}

.Filters {
  font-size: 14px;
  display: flex;
  margin: 16px 0;
  width: fit-content;
  height: 30px;
  align-items: center;
  position: absolute;
  left: 400px;
  z-index: 2;
}
