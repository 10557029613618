.Container {
  background-color: rgba(242, 244, 247, 1);
  height: min(90%, 950px);
  width: min(100%, 680px);
  overflow: auto;
  border-radius: 16px;
  padding: 8px 24px;
}

.Content {
  max-width: 800px;
}

.Title {
  display: flex;
  justify-content: space-between;
  margin: 24px 0 8px 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.Input {
  width: 100%;
}

.CharCounter {
  font-family: "Open Sans";
  color: var(--color-gray1);
  font-size: 12px;
  padding-left: 16px;
}

.CloseButton {
  fill: var(--color-gray1);
}

.Col50 {
  display: grid;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  gap: 16px;
}

.Col50 > div {
  width: 100%;
}

.Col50 > div > * {
  width: 100%;
}

.Actions {
  display: flex;
  margin-top: 16px;
}

.Actions > *:not(:first-child) {
  margin-left: 8px;
}

.Actions > .ButtonRight {
  margin-left: auto;
}

.Actions > .Disabled {
  background-color: var(--color-gray5);
}

@media screen and (max-width: 900px) {
  .Container {
    width: 100%;
    height: 100%;
  }
  .Col50 {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .Actions {
    flex-direction: column-reverse;
    gap: 16px;
  }
  .Actions > *:not(:first-child) {
    margin-left: 0px;
  }
}
