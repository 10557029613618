.Container {
  height: 80vh;
  width: 60vw;
  padding: 16px;
  position: relative;
  border-radius: 15px;
  background-color: white;
  padding-right: 48px;
}

.IframeContainer {
  height: calc(100% - 16px);
  background-color: white;
}

.ButtonContainer {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 16px;
  width: 16px;
}

.ButtonContainer:hover {
  opacity: 0.6;
}

@media only screen and (max-width: 900px) {
  .Container {
    height: 100vh;
    width: 100vw;
    padding-right: 16px;
  }
}
