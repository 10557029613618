.Button {
  vertical-align: top;
  display: inline-block;
  outline: 0;
}

.Content {
  position: fixed;
  z-index: 6;
  background-color: white;
  width: 100vw;
  left: 0;
  padding: 24px;
  bottom: 0px;
  transition: all 0.2s ease;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Close {
  margin-top: 16px;
  width: 100%;
  margin-bottom: 10px;
}

.Backdrop {
  background-color: var(--color-grey800);
  height: 100vh;
  width: 100vw;
  z-index: 5;
  position: fixed;
  top: 0px;
  left: 0;
  transition: all 0.2s ease;
}
