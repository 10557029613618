.Filters {
  font-family: "Open Sans";
  font-size: 14px;
  display: flex;
  height: 50px;
  scrollbar-width: none; /* Firefox */
  width: 100%;
  overflow-x: auto;
  margin-bottom: 0;
  align-items: center;
}

.Filters::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.FiltersButton {
  color: var(--color-grey500);
  background: var(--color-grey100);
  border: 1px solid var(--color-grey300);
  box-sizing: border-box;
  border-radius: 100px;
  margin-right: 4px;
  padding: 6px 15px;
  font-weight: 600;
  height: 38px;
}

.FiltersButton path {
  fill: var(--color-grey500);
}

.FilterSelected {
  background-color: #475467;
  border: 1px solid #475467;
}

.FilterSelected * {
  color: white;
  fill: white !important;
}

.Filters span {
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  user-select: none;
  vertical-align: middle;
}

.Filters span > span {
  margin-left: 6px;
}

.Filters input {
  display: none;
}

.Filters::-webkit-scrollbar {
  display: none;
}
.Filters span:first-child {
  display: none;
}
