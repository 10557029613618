.HeaderText {
  font-weight: 700px;
  font-size: 18px;
  line-height: 28px;
}

.TipList * {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.TipList h5 {
  font-weight: bold;
  margin-top: 4px;
  color: var(--color-grey400);
}

.Select {
  width: 100%;
}

.CreateNew {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  height: 100px;
}

.CreateNew > button {
  width: 140px;
}

.CreateNew > span {
  margin-bottom: 16px;
  color: var(--color-grey800);
}
