:root {
  --color-blue0: #0086c9;
  --color-blue1: #70a4d1;
  --color-blue2: #a6cef1;
  --color-blue3: #e3f2ff;
  --color-gray0: #545454;
  --color-gray1: #666666;
  --color-gray2: #cecece;
  --color-gray3: #e2e2e2;
  --color-gray4: #eeeeee;
  --color-gray5: #a3a3a3;
  --color-orange0: rgba(253, 133, 58, 1);
  --color-orange1: #fff2e4;
  --color-red0: rgba(240, 68, 56, 1);
  --color-red1: #f50000;
  --color-green0: #34a853;
  --pending-pulse: pulse infinite 3000ms;

  --color-success25: #f6fef9;
  --color-success300: #6ce9a6;
  --color-success600: #039855;
  --color-success700: #027a48;

  --color-warning25: #fffcf5;
  --color-warning300: #ffca64;
  --color-warning600: #dc6803;
  --color-warning700: #b54708;

  --color-error25: #fffbfa;
  --color-error300: #fda29b;
  --color-error600: #d92d20;
  --color-error700: #b42318;

  --color-blue500: rgba(11, 165, 236, 1);

  --color-primary100: #d19cbf;
  --color-primary500: rgba(172, 77, 139, 1);
  --color-primary600: rgba(163, 57, 126, 1);
  --color-primary700: #933371;
  --color-primary800: rgba(161, 16, 67, 1);
  --color-pink700: rgba(193, 21, 116, 1);

  --color-grey25: rgba(252, 252, 253, 1);
  --color-grey50: rgba(249, 250, 251, 1);
  --color-grey100: rgba(242, 244, 247, 1);
  --color-grey200: rgba(228, 231, 236, 1);
  --color-grey300: #d0d5dd;
  --color-grey400: rgba(152, 162, 179, 1);
  --color-grey500: rgba(102, 112, 133, 1);
  --color-grey600: rgba(71, 84, 103, 1);
  --color-grey700: #344054;
  --color-grey800: rgba(29, 41, 57, 1);
  --color-grey900: rgba(48, 48, 48, 1);
}

body {
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: -0.02em;
}

p {
  line-height: 1.5em;
}

a {
  text-decoration: none;
  color: var(--color-blue0);
}

h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 1.1em;
}

h2 {
  font-weight: 100;
  font-size: 34px;
  line-height: 1.1em;
}

h3 {
  font-weight: 500;
  font-size: 28px;
  line-height: 1.1em;
}

h4 {
  font-weight: bold;
  font-size: 25px;
}

h5 {
  font-size: 18px;
  font-weight: bold;
}

strong {
  font-weight: 600;
}

body *::-webkit-scrollbar {
  width: 6px;
}

body *::-webkit-scrollbar-thumb {
  background-color: var(--color-gray1);
  border-radius: 5px;
}

body *::-webkit-scrollbar-track {
  background-color: transparent;
}

@keyframes pulse {
  0% {
    background-color: var(--color-blue1);
  }
  50% {
    background-color: var(--color-blue2);
  }
  100% {
    background-color: var(--color-blue1);
  }
}
