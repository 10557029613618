.Container {
  background-color: #f2f4f7;
  max-width: 400px;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
}

.Header {
  position: relative;
  padding: 24px;
  font-size: 24px;
  font-weight: 700;
}

.CloseButton {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  fill: #303030;
}

.Content {
    display: flex;
    justify-content: space-evenly;
    gap: 24px;
    padding: 0 24px 24px;
    align-items: center;
  }