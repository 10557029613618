.Container {
  background-color: white;
  position: fixed;
  bottom: 0;
  height: 66px;
  width: 100vw;
  z-index: 2;
}

.Login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 16px;
  gap: 16px;
}

.Search {
  stroke: var(--color-grey900);
  height: 24px;
  width: 24px;
  margin-bottom: 4px;
}

.Logo svg {
  height: 24px;
  width: 24px;
  margin-bottom: 4px;
}

.isAuth {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 8px 8px;
}

.isAuth > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  width: 30px;
}

.PlusCirlce {
  height: 48px;
  width: 48px;
  fill: none;
  stroke: var(--color-grey400);
  justify-self: center;
}

.deSelected {
  opacity: 0.6;
}

.CreateOptionButton {
  font-size: 18px;
  font-weight: 600;
}

.CreateOptions {
  width: 100%;
}

.CreateOptions > div {
  margin: 24px 8px;
}

.CreateOptions button {
  margin-left: 24px;
}

.CreateOptions svg {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin-bottom: 4px;
  fill: var(--color-grey700);
}

#cameraFileInput {
  display: none;
}

.CameraInputButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CameraInputButton p {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-grey700);
  margin-left: 24px;
}
