.Container {
    display: flex;
}

.Container > div {
    display: flex;
    padding: 5px 8px;
    border: solid var(--color-grey200) 1px;
    border-radius: 100px;
    align-items: center;
    margin: 4px;
    color: var(--color-grey500);
    font-weight: 600;
    cursor: pointer;
}

.Container span {
    background-color: var(--color-blue0);
    color: white;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 100%;
    margin-right: 6px;
}

.Selected span {
    color: var(--color-grey600);
    background-color: white;
}

.Selected {
    background-color: var(--color-grey700);
    color: white !important;
    border: none !important;
}
