.Container {
  background-color: #f2f4f7;
  max-width: 400px;

  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
}

.Header {
  position: relative;
  padding: 24px;
  font-size: 24px;
  font-weight: 700;
}

.CloseButton {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  fill: #303030;
}

@media only screen and (max-width: 900px) {
  .Container {
    max-width: 100%;
    border-radius: 0px;
    box-shadow: 0px 20px 24px -4px hsla(220, 43%, 11%, 0);
  }

  .Header {
    margin-top: 42px;
  }
  .Header > h4 {
    font-weight: 700;
    font-size: 30px;
    line-height: 32px;
  }

  .CloseButton {
    top: -30px;
  }
}

.Content {
  display: flex;
  flex-flow: column;
  padding: 0 24px 24px;
  align-items: center;
}

.Content > *:not(:first-child) {
  margin-top: 16px;
}

.Description {
  max-width: 320px;
  text-align: center;
}

.Options {
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: center;
}

.Options > *:not(:first-child) {
  margin-top: 20px;
}

.OptionsButton {
  font-weight: normal;
  width: 100%;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
}

.OptionsIcon {
  margin-right: 6px;
}

.Email {
  fill: var(--color-gray1);
}

.Form {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.Form > h6:not(:first-child) {
  margin-top: 16px;
}

.Form > div:not(:first-child) {
  margin-top: 4px;
}

.Form > button {
  margin-top: 16px;
  height: 44px;
}

.Input {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  width: 100%;
  position: relative;
}

.Forgot {
  position: absolute;
  right: 8px;
  top: 16px;
}

.TwoInlineInputs > div:not(:first-child) {
  margin-left: 4px;
}

.TwoInlineInputs > div:first-child {
  margin-right: 4px;
}

.LightText {
  color: #667085;
  font-weight: 400;
}
