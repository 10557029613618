.inputContainer {
  display: flex;
  width: 240px;
  justify-content: space-between;
}

.Label {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  margin-bottom: 8px;
  font-size: 16px;
  padding: 16px 0 0;
  color: var(--color-grey700);
  pointer-events: none;
  font-weight: 600;
}

.inputContainer input {
  outline: 0;
  border: 0;
  width: 30px;
  height: 40px;
  background-color: white;
  border-bottom: var(--color-blue0) 1px solid;
  border-radius: 5px 5px 0 0;
  text-align: center;
  font-size: 16px;
  padding-top: 5px;
}
