.Container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 2000;
}

.BackDrop {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100vh;
  background-color: rgba(71, 84, 103, 0.6);
  overflow: auto;
}

.BackDrop > * {
  margin: auto !important;
}

@media only screen and (max-width: 900px) {
  .BackDrop > * {
    margin: 0 !important;
  }
}
