.Container {
  background-color: var(--color-grey100);
  max-width: 680px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.Container .Input {
  align-self: center;
}

.Header {
  position: relative;
  padding: 24px 30px;
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  fill: var(--color-gray1);
}

.Info {
  align-items: center;
  justify-content: center;
}

.topHeader {
  margin-bottom: 3%;
}

.Input {
  width: 100%;
  max-width: 300px;
  margin-bottom: 8px;
  display: block;
}

.Actions {
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 24px;
  margin-left: auto;
}

.Actions > *:not(:first-child) {
  margin-left: 16px;
}
