.Items {
  max-height: 260px;
  overflow: auto;
}

.Item {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #000000;
  line-height: 1.2;
  font-size: 0.9em;
  position: relative;
  padding-right: 16px;
  height: 64px;
  cursor: pointer;
}

.ItemUser {
  height: 84px;
}

.ItemUser > .ItemFlex {
  height: 84px;
}

.ItemFlex {
  display: flex;
  height: 64px;
  align-items: center;
}

.ImageContainer {
  width: 56px;
  margin-left: 4px;
  margin-right: 8px;
}

.InfoColumn {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.InfoRow {
  margin-top: 7px;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.InfoRow path {
  fill: var(--color-grey400);
}

.InfoRow svg {
  height: 16px;
  width: 16px;
}

.InfoRow > span {
  position: relative;
  top: 2px;
  margin-right: 4.5px;
}

.InfoRow > div {
  margin-right: 10px;
  display: flex;
  line-height: 20px;
}

.InfoColumn > div {
  color: var(--color-grey400);
}

.InfoColumn > h5 {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-grey700);
  word-wrap: none;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.ImageWrapper {
  object-fit: cover;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin: 0 4px;
  fill: white;
  background-color: var(--color-grey400);
  align-items: center;
  position: relative;
  display: flex;
  margin-top: 15px;
}

.ImageTipList {
  width: 28px;
  height: 28px;
  margin-left: auto;
  margin-right: auto;
}

.Image {
  object-fit: cover;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin: 0 4px;
  background-color: var(--color-grey400);
  align-self: flex-start;
  position: relative;
}

.Subtitle {
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-top: 8px;
  width: 300px;
  word-wrap: none;
  text-overflow: ellipsis;
  color: var(--color-grey400);
}

.BoxPosition {
  left: 25px;
  top: 34px;
}
