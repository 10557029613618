.Container {
  background-color: rgba(242, 244, 247, 1);
  width: 524px;
  height: min(90%, 600px);
  overflow: auto;
  padding: 27px 48px;
  border-radius: 16px;
}

.Content {
  max-width: 800px;
  position: relative;
}

.Header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100px;
  border-bottom: 1px solid var(--color-grey200);
}

.Header h4 {
  margin-top: 16px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: var(--color-grey800);
}

.TipList:first-child {
  margin-top: 8px;
}

.TipList:last-child {
  border-bottom: none;
}

.TipList {
  height: 48px;
  border-bottom: 1px solid var(--color-grey200);
  margin-top: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.TipList svg {
  margin: 0 10px;
  fill: var(--color-success300);
  position: absolute;
}

.TipList * {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  transition: all 0.2s ease;
}

.TipList > span {
  position: absolute;
}

.TipList h4 {
  color: var(--color-grey700);
}

.TipList h5 {
  font-weight: bold;
  margin-top: 4px;
  color: var(--color-grey400);
}

.IconAnimation {
  transform: translateX(44px);
}

.Show {
  opacity: 1;
}

.Hide {
  opacity: 0;
}

.Close {
  position: absolute;
  top: 0;
  right: 0;
  fill: var(--color-grey900);
}
