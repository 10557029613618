.Container {
  color: var(--color-grey500);
  background-color: var(--color-grey50);
  padding: 20px 0;
}

.Content {
  min-height: 220px;
  height: 100%;
  font-size: 16px;
  font-family: "Open Sans";
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.Content > *:not(:first-child) {
  margin-top: 40px;
}

.SiteLinks {
  align-self: center;
  display: inline-flex;
  justify-content: space-between;
  flex-flow: row wrap;
  overflow: hidden;
  list-style: disc;
}

.SiteLinks > *:not(:first-child) {
  margin-left: 12px;
}

.SiteLinks > *:not(:last-child) {
  margin-right: 12px;
}

.SiteLinks > li::marker {
  font-size: 0;
}

.SiteLinks a {
  color: var(--color-grey500);
}

.Info {
  display: flex;
  justify-content: space-between;
  color: var(--color-grey400);
}

@media only screen and (max-width: 1000px) {
  .Info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .Info > * {
    margin-bottom: 20px;
  }
}

.MobileSiteLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.MobileSiteLinks a {
  color: var(--color-grey500);
}

.MobileGrouped {
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 32px;
}

.Info > .SiteLinks {
  justify-self: center;
}

.SocialLinks {
  display: inline-flex;
  align-items: center;
  filter: contrast(0.5);
  z-index: 0;
}

.SocialLinks > *:not(:first-child) {
  margin-left: 10px;
}

.SocialLinks > *:not(:last-child) {
  margin-right: 10px;
}

.divider {
  border-top: 1px solid var(--color-grey200);
}
